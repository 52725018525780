@use '@angular/material' as mat;
@use 'typography' as typography;
@include mat.core();

$sesame-cs-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$sesame-cs-accent: mat.m2-define-palette(mat.$m2-pink-palette);
$sesame-cs-theme: mat.m2-define-light-theme((
  color: (
    primary: $sesame-cs-primary,
    accent: $sesame-cs-accent,
    warn: $sesame-cs-accent,
  ),
  density: 0,
  typography: typography.$config,
));

@include mat.all-component-themes($sesame-cs-theme);

:root {
  --sesame-primary-color: #{mat.m2-get-color-from-palette($sesame-cs-primary)};
  --sesame-primary-table-row-selected-color: #F0F1F9;
  --sesame-primary-light-color: #{mat.m2-get-color-from-palette($sesame-cs-primary, 100)};
  --sesame-secondary-color: #{mat.m2-get-color-from-palette($sesame-cs-accent)};
  --sesame-error-color: #ED1111;
  --sesame-error-light-color: #FCE2E2;
  --sesame-warning-color: #FB8C00;
  --sesame-warning-light-color: #FEF1E0;
  --sesame-success-color: #03A168;
  --sesame-success-light-color: #E0F3EC;
  --sesame-black-color: #323232;
  --sesame-white-color: #FFFFFF;
  --sesame-yellow-color: #FDF7E0;
  --sesame-red-color: #C50E0E;
  --sesame-gray-color: #F6F8F8;

  --sesame-primary-text-color: #323232;
  --sesame-secondary-text-color: #5D5D5D;
  --sesame-tertiary-text-color: #8D8D8D;
  --sesame-stroke-color: #BFBFBF;
  --sesame-stroke-2-color: #EBEBEB;
}