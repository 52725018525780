@use 'color' as color;

@mixin checkbox($color) {
  .mdc-checkbox .mdc-checkbox__native-control:focus:checked ~ .mdc-checkbox__ripple {
    background-color: $color !important;
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
    background-color: $color !important;
    border-color: $color !important;
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
    background-color: $color !important;
    border-color: $color !important;
  }
}

@include checkbox(var(--sesame-primary-color));