.mat-mdc-icon-button.mat-mdc-button-base {
  font-size: 14px !important;
  width: 48px;
  height: 48px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mdc-button {
  display: flex;
  flex: none;
  padding: 0 12px;

  .mdc-button__label {
    white-space: nowrap;
    fa-icon{
      padding-right: 4px;
    }
  }
}
