@use '@angular/material' as mat;
@use 'color' as color;

$font-family: 'Open Sans', sans-serif;

$small-font-size: 12px;
$standard-font-size: 14px;
$big-font-size: 16px;
$very-big-font-size: 24px;
$max-font-size: 28px;

$standard-font-weight: 400;
$bolded-font-weight: 600;
$max-font-weight: 700;

$standard-line-height: 20px;
$big-line-height: 24px;
$very-big-line-height: 28px;
$max-line-height: 38px;

@mixin section-title {
  font-family: $font-family;
  font-style: normal;
  font-weight: $max-font-weight;
  font-size: $max-font-size;
  line-height: $max-line-height;
  letter-spacing: -.02em;
  color: color.$primary-text;
  display: flex;
  justify-content: space-between;
}

@mixin title {
  font-family: $font-family;
  font-style: normal;
  font-weight: $standard-font-weight;
  font-size: $big-font-size;
  line-height: $big-line-height;
  letter-spacing: -.02em;
  color: color.$primary-text;
}

@mixin subtitle {
  font-family: $font-family;
  font-style: normal;
  font-weight: $bolded-font-weight;
  font-size: $big-font-size;
  line-height: $very-big-line-height;
}

@mixin standard {
  font-family: $font-family;
  font-style: normal;
  font-weight: $standard-font-weight;
  font-size: $standard-font-size;
  line-height: $standard-line-height;
  color: color.$black;
  letter-spacing: 0.4px;
}

$config: mat.m2-define-typography-config(
  $font-family: $font-family,
  $headline-1: mat.m2-define-typography-level(
    $font-size: $very-big-font-size,
    $line-height: $very-big-line-height
  ),
  $headline-2: mat.m2-define-typography-level(
    $font-size: $very-big-font-size,
    $line-height: $very-big-line-height
  ),
  $headline-3: mat.m2-define-typography-level(
    $font-size: $very-big-font-size,
    $line-height: $very-big-line-height
  ),
  $headline-4: mat.m2-define-typography-level(
    $font-size: $very-big-font-size,
    $line-height: $very-big-line-height
  ),
  $headline-5: mat.m2-define-typography-level(
    $font-size: $very-big-font-size,
    $line-height: $very-big-line-height
  ),
  $subtitle-1: mat.m2-define-typography-level(
    $font-size: $big-font-size,
    $line-height: $big-line-height
  ),
  $subtitle-2: mat.m2-define-typography-level(
    $font-size: $big-font-size,
    $line-height: $big-line-height
  ),
  $body-1: mat.m2-define-typography-level(
    $font-size: $standard-font-size,
    $line-height: $standard-line-height
  ),
  $body-2: mat.m2-define-typography-level(
    $font-size: $standard-font-size,
    $line-height: $standard-line-height
  ),
  $caption: mat.m2-define-typography-level(
    $font-size: $small-font-size,
    $line-height: $standard-line-height
  ),
  $button: mat.m2-define-typography-level(
    $font-size: $standard-font-size,
    $line-height: $standard-line-height
  ),
  $overline: mat.m2-define-typography-level(
    $font-size: $standard-font-size,
    $line-height: $standard-line-height
  ),
)