@use 'color' as color;

@mixin title($color) {
  .mat-mdc-dialog-title {
    color: $color !important;
  }
}

@include title(var(--sesame-primary-color));

