@use '@angular/material' as mat;
@use 'typography' as typography;

$vanin-primary: (
  50 : #f5e3f0,
  100 : #e7b9da,
  200 : #d78bc2,
  300 : #c75caa,
  400 : #bb3997,
  500 : #af1685,
  600 : #a8137d,
  700 : #9f1072,
  800 : #960c68,
  900 : #860655,
  A100 : #ffb3df,
  A200 : #ff80c9,
  A400 : #ff4db3,
  A700 : #ff34a8,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);
$sesame-accent: (
  50 : #fdf5e0,
  100 : #fbe5b3,
  200 : #f9d480,
  300 : #f6c34d,
  400 : #f4b626,
  500 : #f2a900,
  600 : #f0a200,
  700 : #ee9800,
  800 : #ec8f00,
  900 : #e87e00,
  A100 : #ffffff,
  A200 : #ffeddc,
  A400 : #ffd3a9,
  A700 : #ffc690,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$sesame-oss-primary: mat.m2-define-palette($vanin-primary);
$sesame-oss-accent: mat.m2-define-palette($sesame-accent);

$vanin-theme: mat.m2-define-light-theme((
  color: (
    primary: $sesame-oss-primary,
    accent: $sesame-oss-accent,
    warn: $sesame-oss-accent,
  ),
  density: 0,
  typography: typography.$config,
));

.oss-theme {
  @include mat.all-component-themes($vanin-theme);
}

:root {
  .oss-theme {
    --sesame-primary-color: #{mat.m2-get-color-from-palette($sesame-oss-primary)};
    --sesame-primary-light-color: #FBF3F9;
    --sesame-secondary-color: #{mat.m2-get-color-from-palette($sesame-oss-accent)};
    --sesame-primary-table-row-selected-color: #f9ecf5;
  }
}