@use 'color' as color;

.mat-mdc-tab-label {
  opacity: 1 !important;
}

.mat-mdc-tab-label-active {
  color: var(--sesame-primary-color)
}

.mat-mdc-tab-labels {
  border-bottom: solid 1px color.$tab-border-bottom-color;
}