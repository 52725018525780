@use 'color' as color;

@mixin chip($color) {
  .mat-mdc-chip {
    border: 1px solid $color !important;
    background-color: color.$white !important;

    .mdc-evolution-chip__text-label, .mdc-evolution-chip__checkmark {
      color: $color !important;
    }

    & .mat-mdc-chip-remove {
      opacity: 1 !important;

      & fa-icon {
        color: $color !important;
      }
    }
  }
}

@include chip(var(--sesame-primary-color));