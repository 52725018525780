.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-33 {
  width: 33.33% !important;
}

.w-45 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.pointer {
  cursor: pointer;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.text-wrap {
  text-wrap: wrap !important;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 8px !important;
}

.gap-2 {
  gap: 16px !important;
}

.gap-3 {
  gap: 24px !important;
}

.gap-4 {
  gap: 32px !important;
}

.gap-5 {
  gap: 40px !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 8px !important;
}

.m-2 {
  margin: 16px !important;
}

.m-3 {
  margin: 24px !important;
}

.m-4 {
  margin: 32px !important;
}

.m-5 {
  margin: 40px !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.mx-2 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.mx-3 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.mx-4 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.mx-5 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-2 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-3 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-4 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.my-5 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 8px !important;
}

.mt-2 {
  margin-top: 16px !important;
}

.mt-3 {
  margin-top: 24px !important;
}

.mt-4 {
  margin-top: 32px !important;
}

.mt-5 {
  margin-top: 40px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 8px !important;
}

.mr-2 {
  margin-right: 16px !important;
}

.mr-3 {
  margin-right: 24px !important;
}

.mr-4 {
  margin-right: 32px !important;
}

.mr-5 {
  margin-right: 40px !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 8px !important;
}

.mb-2 {
  margin-bottom: 16px !important;
}

.mb-3 {
  margin-bottom: 24px !important;
}

.mb-4 {
  margin-bottom: 32px !important;
}

.mb-5 {
  margin-bottom: 40px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 8px !important;
}

.ml-2 {
  margin-left: 16px !important;
}

.ml-3 {
  margin-left: 24px !important;
}

.ml-4 {
  margin-left: 32px !important;
}

.ml-5 {
  margin-left: 40px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.ml-25p {
  margin-left: 25%;
}

.ml-50p {
  margin-left: 50%;
}

.ml-75p {
  margin-left: 75%;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 8px !important;
}

.p-2 {
  padding: 16px !important;
}

.p-3 {
  padding: 24px !important;
}

.p-4 {
  padding: 32px !important;
}

.p-5 {
  padding: 40px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.px-2 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.px-3 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.px-4 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.px-5 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-2 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-3 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-4 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.py-5 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 8px !important;
}

.pt-2 {
  padding-top: 16px !important;
}

.pt-3 {
  padding-top: 24px !important;
}

.pt-4 {
  padding-top: 32px !important;
}

.pt-5 {
  padding-top: 40px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 8px !important;
}

.pr-2 {
  padding-right: 16px !important;
}

.pr-3 {
  padding-right: 24px !important;
}

.pr-4 {
  padding-right: 32px !important;
}

.pr-5 {
  padding-right: 40px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 8px !important;
}

.pb-2 {
  padding-bottom: 16px !important;
}

.pb-3 {
  padding-bottom: 24px !important;
}

.pb-4 {
  padding-bottom: 32px !important;
}

.pb-5 {
  padding-bottom: 40px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 8px !important;
}

.pl-2 {
  padding-left: 16px !important;
}

.pl-3 {
  padding-left: 24px !important;
}

.pl-4 {
  padding-left: 32px !important;
}

.pl-5 {
  padding-left: 40px !important;
}

.bottom-border {
  border-bottom: 1px solid #BFBFBF;
}
