.mat-button-toggle {
  min-width: 124px;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard {
  border-color: var(--sesame-primary-color);
  font-weight: 600;

  .mat-button-toggle-label-content {
    color: var(--sesame-primary-color);
  }

  .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    background-color: var(--sesame-primary-light-color);
  }
}

