@use 'color' as color;

mat-card {
  .mat-mdc-card-header {
    @extend .justify-content-between;
    @extend .align-items-center;
    height: 40px;
    margin-bottom: 8px;
    .mat-mdc-card-header-text{
      margin: 0;
      width: 100%;
    }
    .mat-mdc-mdc-card-title {
      margin-bottom: 0;
    }
  }
  mat-card-title {
    font-size: 16px !important;
    color: var(--sesame-primary-color);
  }
}
