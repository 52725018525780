$grey-1: #5D5D5D;
$grey-2: #8D8D8D;
$grey-3: #F5F5F6;
$error: #ED1111;
$error-light: #FCE2E2;
$warning: #FB8C00;
$warning-light: #FEF1E0;
$success: #03A168;
$success-light: #E0F3EC;
$black: #323232;
$white: #FFFFFF;
$yellow: #FDF7E0;
$red: #C50E0E;
$orange: orange;
$navy: #004D94;

$primary-text: #323232;
$secondary-text: #5D5D5D;
$tertiary-text: #8D8D8D;
$stroke: #BFBFBF;
$stroke-2: #EBEBEB;

$tab-border-bottom-color: transparentize($grey-2, 0.6);