// module level classes
@use '@angular/material' as mat;
@use 'typography' as typography;
@use 'color' as color;
@import 'util';
@import 'stepper';
@import 'mat-card';
@import 'mat-chip';
@import 'mat-list';
@import 'mat-checkbox';
@import 'mat-header';
@import 'mat-dialog-title';
@import 'mat-slide-toggle';
@import 'mat-dialog';
@import 'mat-button';
@import 'mat-button-toggle-group';
@import 'mat-toolbar';
@import 'mat-tab';
@import 'custom';
@import 'snack-bar';
@import 'mat-optgroup';

.primary-color {
  color: var(--sesame-primary-color);
}

.error-color {
  color: var(--sesame-error-color);
}

.success-color {
  color: var(--sesame-success-color);
}

.warning-color {
  color: var(--sesame-warning-color);
}

.black-color {
  color: var(--sesame-black-color);
}

html {
  --lib-typography_font-family: typography.$font-family;

  .mat-mdc-icon-button {
    --mdc-icon-button-icon-size: 20px !important;
  }

  font-size: 14px;
}

mat-sidenav {
  * {
    font-size: 16px;
  }
}

h5 {
  margin: 0 0 12px;
}

fa-icon {
  font-size: initial;
}

/* You can add global styles to this file, and also import other style files */

$middle-length: 16px;

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: typography.$font-family;
}

table {
  width: 100%;
  margin-bottom: 12px;
}

.mat-mdc-cell, .mat-mdc-header-cell {
  padding: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
mat-tab-body {
  padding-top: $middle-length;
}

mat-form-field {
  width: 100%;
}

.mat-vertical-content-container {
  padding-top: $middle-length;
}

.padding-bottom-16 {
  padding-bottom: $middle-length;
}

section.actions {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
  padding-bottom: 8px;

  button {
    @extend .action-button;

    &:not(:first-child) {
      margin-left: 8px;
    }

    fa-icon {
      margin-right: 8px;
    }
  }
}

.align-text-center {
  text-align: center;
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-content.mat-mdc-dialog-content {
  max-height: 90vh;

  form {
    padding-top: 10px;
  }
}

mat-form-field {
  display: block;

  &.w-10 {
    width: calc(10% - 16px) !important;
    margin-right: 16px;
  }

  &.w-15 {
    width: calc(15% - 16px) !important;
    margin-right: 16px;
  }

  &.w-20 {
    width: calc(20% - 16px) !important;
    margin-right: 16px;
  }

  &.w-25 {
    width: calc(25% - 16px) !important;
    margin-right: 16px;
  }

  &.w-33 {
    width: calc(33.33% - 16px) !important;
    margin-right: 16px;
  }

  &.w-40 {
    width: calc(40% - 16px) !important;
    margin-right: 16px;
  }

  &.w-45 {
    width: calc(45% - 16px) !important;
    margin-right: 16px;
  }

  &.w-50 {
    width: calc(50% - 16px) !important;
    margin-right: 16px;
  }

  &.w-60 {
    width: calc(60% - 16px) !important;
    margin-right: 16px;
  }

  &.w-75 {
    width: calc(75% - 16px) !important;
    margin-right: 16px;
  }
}

.flex-spacer {
  flex: 1 1 auto;
}


form {
  .d-flex {
    mat-form-field {
      flex: auto;
    }
  }
}

.show-text-link {
  color: var(--sesame-primary-color);
  font-weight: 700;

  &:hover {
    cursor: pointer;
  }
}

.action-button.mat-mdc-unelevated-button.mat-primary:not([disabled]) {
  background-color: var(--sesame-primary-color);
}

.action-button {
  min-width: 150px;
}

.action-input {
  margin-right: 16px;
  // make width to fit all available space minus action-button width and standard margin
  width: calc(100% - 166px);
}

mat-label {
  fa-icon {
    vertical-align: top !important;
    padding: 0 4px;
  }
}


/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card-title {
  fa-icon {
    padding-right: 5px;
  }
}


.section-label {
  color: color.$primary-text;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}