@use 'color' as color;

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: color.$grey-2 !important;
}

@mixin slide($color) {
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $color !important;
  }
}

@include slide(var(--sesame-primary-color));