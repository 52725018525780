@use 'color' as color;

@mixin list($color) {
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  mat-list-option {
    background-color: unset !important;

    &[aria-selected="true"] {
      /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
      & div.mat-list-item-content {
        background-color: $color;
        border-radius: 5px;

        .list-container {
          color: color.$white;
          background-color: $color;
        }
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    & div.mat-list-item-content {
      background-color: color.$grey-3;
      border-radius: 5px;

      &:hover {
        background-color: $color;

        .list-container {
          color: color.$white;
          background-color: $color;
        }

        & mat-pseudo-checkbox {
          border-color: color.$white !important;
        }
      }
    }
  }
}

@include list(var(--sesame-primary-color));