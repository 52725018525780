.mat-step-header[aria-labelledby="disabled"] {
  pointer-events: none !important;
  cursor: not-allowed;
}

.mat-step-label {
  width: 100%;

  .mat-stepper-label-content {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}
